import React, { useState } from "react";

import { StakingForm } from "./StakingForm";
import { TransactionErrorMessage } from "./TransactionErrorMessage";
import { WaitingForTransactionMessage } from "./WaitingForTransactionMessage";
import { useEth } from "../contexts/EthContext";
import { _withDraw, _stake } from "./Utils";
import { _getReward, _claimReward } from "./Utils";
import { _getVested } from "./Utils";
import ethers from "ethers";
import {TokenVestingChart} from "./VPGraph";
import {SimpleInterestChart} from "./SIGraph";
import { Container, Row, Col, Button, Label } from "reactstrap";


export function Home({
  contractData,
  stakedBalance,
  vestedBalance,
  rewardBalance,
  setRewardBalance,
  setVestedBalance,
}) {
  const {
    state: { artifact, web3, accounts, networkID, contract, selectedAddress },
    tryInit,
  } = useEth();
  const [buttonText, setButtonText] = useState("Stake");

  const vestedBalanceFixed = Number(vestedBalance).toFixed(3);
  const totalStakedTokensFixed = Number(
    contractData?.totalStakedTokens
  ).toLocaleString();
  const dollarPrice = Number(
    contractData?.totalStakedTokens * 0.15
  ).toLocaleString();
  const rewardBalanceFixed = Number(rewardBalance).toFixed(3);

  return (
    <Container fluid className="contain">
      <Row className="row-margin">
        <Col lg={true} md={5} xs={12} className="card" id="manage">
          <h4>Total Staked Tokens</h4>
          <p>
            <b className="stakedTokens">
              {" "}
              {isNaN(stakedBalance) ? "" : stakedBalance}
            </b>{" "}
            <p className="bcx">BCX</p>
            <TokenVestingChart totalTokens={stakedBalance} vestingPeriod={24} cliff={3} vestingStart={0} />
          </p>
          <p>
           
            {"  "}

            <img src="/info.png" className="info" alt="info.png"></img>
            <p className="inf">info</p>
          </p>
        </Col>

        <Col lg={true} md={5} xs={12} className="card">
          <p>
            <h4>Unlocked tokens </h4>
            <span>(excl. rewards)</span>
          </p>
          <p>
            <b className="stakedTokens">
              {" "}
              {isNaN(vestedBalance) ? "" : vestedBalanceFixed}
            </b>
            <p className="bcx">BCX</p>
          
            {"  "}
            <br />
            <br />
            <Button
              className="button"
              onClick={async () => {
                await _getReward(
                  contract,
                  accounts,
                  selectedAddress,
                  setRewardBalance
                );
              }}
            >
              Update Vested Tokens
            </Button>{" "}
            <img src="/info.png" className="info" alt="info.png"></img>
            <p className="inf">info</p>
          </p>
        </Col>
        <Col lg={true} md={10} xs={12} className="card">
          {true && (
            <StakingForm
              stakeTokens={(amount) => {
                _stake(amount, selectedAddress, contract);
              }}
              withDraw={(amount) => {
                _withDraw(amount, selectedAddress, contract);
              }}
              tokenSymbol="BCX"
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col id="man" lg={3} md={5} xs={12} className="card ">
          <h4>Rewards Tokens</h4>
          {/* <p>
            View and manage your rewards balance. All rewards are always
            unlocked
          </p> */}
          <p>
            {" "}
            <b className="stakedTokens">
              {isNaN(rewardBalance) ? "" : rewardBalanceFixed}
            </b>{" "}
            <p className="bcx"> BCX </p> {"  "}
            <SimpleInterestChart principal={rewardBalanceFixed} rate={10} timePeriod={24} />
            <br />
            <br />
            <p>
              <Button
                md={3}
                xs={2}
                className="button"
                onClick={async () => {
                  await _getReward(
                    contract,
                    accounts,
                    selectedAddress,
                    setRewardBalance
                  );
                }}
              >
                Update Rewards{" "}
              </Button>{" "}
              <Button
                md={3}
                xs={2}
                className="button2 bg-transparent"
                onClick={async () => {
                  await _claimReward(
                    selectedAddress,
                    contract,
                    setRewardBalance
                  );
                }}
              >
                Withdraw Rewards
              </Button>{" "}
              <img src="/info.png" className="info" alt="info.png"></img>
              <p className="inf">info</p>
              {/*onClick={this._claimReward}*/}
            </p>
          </p>
        </Col>

        <Col lg={true} md={6} xs={12} className="card" id="stInfo">
          <Container>
            <Row>
              <Col className="Col-mobile">
                <b>
                  <Label xs={12} className="title">
                    Total Staked Pool:
                  </Label>

                  <Label xs={12} className="labeltxt">
                    {isNaN(contractData?.totalStakedTokens)
                      ? ""
                      : totalStakedTokensFixed} {" "}
                    BCX
                  </Label>
                </b>
              </Col>
              <Col>
                <b>
                  <Label xs={12} className="title">
                    Total Value Locked(TVL):
                  </Label>

                  <Label xs={12} className="labeltxt">
                    ${isNaN(contractData?.totalStakedTokens) ? "" : dollarPrice}
                  </Label>
                </b>
              </Col>

              <Col>
                <b>
                  <Label xs={12} className="title">
                    Reward Rate:{" "}
                  </Label>

                  <Label xs={12} className="labeltxt">
                    10%  p.a.
                  </Label>
                </b>
              </Col>
            </Row>
          </Container>
          <br />
        </Col>
      </Row>
    </Container>
  );
}
